/**
 * Copyright 2020 Marius Wilms, Christoph Labacher. All rights reserved.
 * Copyright 2019 Atelier Disko. All rights reserved.
 *
 * Use of this source code is governed by a BSD-style
 * license that can be found in the LICENSE file.
 */

.color-card {
  width: 100%;
  max-width: calc(var(--spacing-unit) * 18);
  margin: var(--documentation-component-spacing) 0;

  border: none;
  padding: none;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  cursor: pointer;
  text-align: left;
}

.color-card:active {
  color: inherit;
}

.color-card:first-child {
  margin-top: 0;
}

.color-card:last-child {
  margin-bottom: 0;
}

.color-card__demo {
  width: 100%;
  height: calc(var(--spacing-unit) * 15);
  border-radius: 3px;
  margin-bottom: calc(var(--spacing-unit) * 0.5);
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.color-card--is-ultra-light .color-card__demo {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.color-card__demo::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: inherit;
}

.color-card__demo::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(./CheckerboardPattern.png);
  background-size: 140px;
  z-index: 0;
}

.color-card__score {
  z-index: 2;
  padding: calc(var(--spacing-unit) * 1);
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-beta);
  font-weight: 700;
  line-height: 1em;
  opacity: 0;
  transition: all 0.2s 0.1s;
  text-align: center;
}

.color-card__score :first-child {
  color: white;
}

.color-card__score :last-child {
  color: black;
}

.color-card__copied-indicator {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  z-index: 2;
  text-align: center;
  padding: calc(var(--spacing-unit) * 1);
  opacity: 0;
  transition: opacity 0.7s;
}

.color-card__copied-indicator--is-visible {
  opacity: 1;
  transition: opacity 0.1s;
}

.color-card__demo:hover .color-card__score {
  opacity: 1;
  transition: all 0.3s 0.4s;
}

.color-card__name {
  font-size: var(--font-size-gamma);
  line-height: 1.4em;
  font-weight: 700;
}

.color-card__id {
  color: var(--color-gray-700);
  font-weight: 500;
  opacity: 0;
  transition: all 0.4s 0.2s;
  display: none;
}

.color-card:hover .color-card__id {
  opacity: 1;
}

.color-card__spec {
  font-size: var(--font-size-beta);
  line-height: 1.5em;
  color: var(--color-gray-600);
  margin-bottom: calc(var(--spacing-unit) * 0.5);
}

.color-card__comment {
  font-size: var(--font-size-beta);
  line-height: 1.5em;
}

.color-card--is-compact {
  max-width: calc(var(--spacing-unit) * 10);
}

.color-card--is-compact .color-card__demo {
  height: calc(var(--spacing-unit) * 10);
}

.color-card--is-compact .color-card__name {
  font-size: var(--font-size-beta);
  line-height: 1.4em;
}
