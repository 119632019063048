/*!
 * Copyright 2020 Marius Wilms, Christoph Labacher. All rights reserved.
 * Copyright 2019 Atelier Disko. All rights reserved.
 * Coypright 2018 Bram de Haan. All rights reserved.
 * Copyright 2012 Chris Kempson. All rights reserved.
 *
 * Use of this source code is governed by a BSD-style
 * license that can be found in the LICENSE file.
 */

/* Atelier-Forest Comment */
.hljs-comment,
.hljs-quote {
  color: #766e6b;
}

/* Atelier-Forest Red */
.hljs-variable,
.hljs-template-variable,
.hljs-attribute,
.hljs-tag,
.hljs-name,
.hljs-regexp,
.hljs-link,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class {
  color: #ff0019;
}

/* Atelier-Forest Orange */
.hljs-number,
.hljs-meta,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params {
  color: #00a791;
}

/* Atelier-Forest Green */
.hljs-string,
.hljs-symbol,
.hljs-bullet {
  color: #06be78;
}

/* Atelier-Forest Blue */
.hljs-title,
.hljs-section {
  color: var(--color-blue);
}

/* Atelier-Forest Purple */
.hljs-keyword,
.hljs-selector-tag {
  color: #6289f5;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
